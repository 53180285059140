.gx-main-content-wrapper {
  position: relative;
  padding: 0;
}

#root .ant-layout {
  min-height: 100%;
}

.env-dropdown {
  padding: 0px 96px;
  justify-content: flex-end;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.toastBody {
  margin: 0px;
  padding: 0px;
}

@media screen and (max-width: 1024px) {
  .env-dropdown {
    padding: 0px 66px;
  }
}