@import '../../styles/variables.less';
@import '../../styles/global/variables.less';

.full-height-card.add-members .ant-card-body {
  padding: 132px 101px 176px;
}

.member-search-element {
  .anticon.ant-input-clear-icon,
  .ant-input-clear-icon {
    color: @primary-green-color;
  }
}

.add-members .input-box {
  max-width: 100%;
}

.add-members .ant-select {
  width: 85%;
  background: transparent;
}

.add-members .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: @white-color;
}

.add-members .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 10px;
}

.add-members .ant-form-item .ant-select {
  width: 100%;
}

.add-members .input-box {
  max-width: 100%;
}

.member-wrapper .view-member {
  background-color: transparent;
  border: 0;
}

.member-wrapper .delete-member {
  background-color: transparent;
  border: 0;
}

.member-wrapper .add-member {
  background-color: transparent;
  border: 0;
}

.member-wrapper .ant-btn:hover {
  background: transparent;
}

.full-height-card.landing-card .member-wrapper .ant-card-body {
  padding: 0;
}

.full-height-card.landing-card .ant-card {
  background: transparent;
  border: 0;
}

.add-members .ant-row {
  display: block;
}

.add-members .ant-row label {
  font-size: 15px;
}

.add-members .ant-row .ant-form-item-control {
  margin-top: 10px;
}

.add-member .ant-row .ant-form-item-control {
  margin-top: 10px;
}

.member-wrapper .ant-btn:active {
  background: transparent;
}

.anticon.anticon-loading.anticon-spin {
  color: @black-color;
}

.member-content-container {
  padding: 0px 5px;

  .member-header {
    color: whitesmoke;
    padding: 0px 20px;
    margin-top: 25px;
  }

  .search-create-button-container {
    padding: 0px 15px;

    .primary-button {
      min-width: 100%;
      margin-top: 20px;
    }

    .search-input {
      margin-top: 20px;
      max-width: 100%;
    }
  }
}

.member-modal .ant-modal-content {
  background-color: @table-cell-color;
  border-radius: 24px;
  width: 500px;

  .ant-btn > .ant-btn-loading-icon .anticon svg {
    fill: @white-color;
  }
}

.member-search {
  display: flex;
  justify-content: space-between;
}

.member-title {
  margin-left: 5px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: @font-size-15;
  color: @white-color;
}

.member-wrapper {
  height: calc(100vh - 350px);
  overflow: auto;
  margin-top: 25px;

  .member-container {
    width: 98%;
    height: auto;
    border: 1px solid @secondary-green-color;
    box-sizing: border-box;
    border-radius: 20px;
    padding: 30px 10px 0px 30px;
  }

  .member-header {
    display: flex;
    flex-flow: row;
  }

  .left-header {
    flex: 1;
    display: flex;
    flex-flow: row;
    align-items: center;
  }

  .right-header {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .member-name {
    color: @send-color;
  }

  .add-member {
    color: @add-member-text;
  }

  .delete-member {
    color: @delete-project-text;
  }

  .view-member {
    color: @view-project-text;
  }

  .delete-member {
    color: @primary-color;
  }

  .member-body {
    display: flex;
    flex-flow: column;
    padding-top: 20px;
  }

  .body-item {
    display: flex;
    flex-flow: row;
    margin: 3px 0;
  }

  .item-title {
    color: @logo-color;
  }

  .user-bar {
    width: 200px;
    height: 25px;
    margin-left: 10px;
    background: @secondary-green-color;
    border: 1px solid @send-color;
    box-sizing: border-box;
    border-radius: 100px;
  }

  .right-platform {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
  }

  .date-tab {
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: 10px;
    width: 120px;
    height: 40px;
    margin-left: 50px;
    background: @primary-red-color;
    border-radius: 10px;
    font-size: @font-size-10;
  }

  .left-services {
    width: 70%;
  }

  .right-services {
    justify-content: flex-end;
    font-style: italic;
    color: @send-color;
  }
}

.no-member {
  width: 100%;
  max-width: 584px;
  height: calc(100% - 169px);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .img-section {
    height: 160px;
    width: 200px;
    margin: 0px 30px;
  }

  .text-section {
    font-size: 24px;
    font-weight: 400;
  }
}

div.member-wrapper > div:not(:first-child) {
  margin-top: 3%;
}

.member-modal {
  .add-container {
    height: auto;
    padding: 5px;
  }

  .input-box {
    max-width: 100%;
  }

  .ant-select {
    width: 100%;
  }
}

.no-member {
  display: flex;
  width: 100%;
  max-width: 584px;
  height: 100%;
  justify-content: space-between;
  align-items: center;

  .img-section {
    height: 160px;
    width: 200px;
    margin: 0px 30px;
  }

  .text-section {
    font-size: 24px;
    font-weight: 400;
  }
}

.ant-card-body .without-sub-header-content .ant-tabs-content-holder {
  height: calc(100vh - 200px);
}

.invite-member .ant-row {
  flex-flow: row nowrap;
}

@media only screen and (max-width: 1200px) {
  .member-wrapper {
    height: calc(100vh - 420px);
  }
}

@media screen and (max-width: 1024px) {
  .ant-card.full-height-card.add-projects .ant-card-body {
    padding: 80px 20px;
  }

  .ant-card.full-height-card.landing-card .ant-card-body {
    padding: 40px 20px;
  }

  .ant-card.full-height-card
    .ant-card-body
    .ant-tabs-tab.ant-tabs-tab
    .ant-tabs-tab-btn {
    padding: 10px 20px;
  }

  .full-height-card .ant-card-body .global-container {
    margin-left: 20px;
    margin-top: 20px;
  }
}

.ant-select-selection-overflow {
  overflow: auto;
  max-height: 150px;
}
