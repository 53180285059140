@import '../../styles/variables.less';
@import '../../styles/global/variables.less';

.channels h1 {
  color: @white-color;
}

.channels .ant-table {
  background: transparent;
}

.channels .ant-table .ant-table-thead>tr>th {
  background: transparent;
}

.channels .ant-table .ant-table-thead {
  position: relative;
}

.channels .ant-table .ant-table-thead::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  border: 1px solid @secondary-green-color;
  border-radius: 20px;
}

.channels .ant-table .ant-table-thead>tr>th {
  padding: 0px;
  border-bottom: none;
  color: @white-color;
}

.channels .ant-table .ant-table-thead>tr>th.ant-table-cell-scrollbar {
  display: none;
}

.channels .ant-table .ant-table-tbody>tr>td {
  border-bottom: none;
  color: @white-color;
}

.channels .ant-table .ant-empty-description {
  color: @white-color;
}

.channels .ant-table .ant-table-tbody>tr.ant-table-placeholder:hover>td {
  background-color: transparent;
}

.channels .ant-table .ant-table-tbody>tr.ant-table-row:hover>td {
  background-color: @secondary-green-color;
}

.channel-modal .ant-form-item .ant-select {
  width: 100%;
}

.channels .mail span {
  margin: 5px;
}

.channels .ant-btn:active {
  color: @white-color;
  background-color: @primary-color;
}

.channels .ant-btn:focus {
  color: @white-color;
  background-color: @primary-color;
}

.channels .ant-table-tbody>tr.ant-table-row:hover>td:first-child {
  border-radius: 20px 0 0 20px;
}

.channels .ant-table-tbody>tr.ant-table-row:hover>td:last-child {
  border-radius: 0 20px 20px 0;
}

.channels .ant-table-tbody>tr>td {
  transition: none;
}

.channels .action-button button.ant-btn:hover {
  color: @white-color;
}

.text-area .ant-form-item-explain,
.ant-form-item-extra {
  color: @white-color;
  margin-top: 5px;
}

.add-container .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.add-container .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-right: 4px;
  color: @white-color;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.add-container .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  background-color: @error-background;
}

.select_dropdown {
  display: none;
}

.add-member .ant-row {
  flex-flow: row nowrap;
}

.send-email .ant-row {
  flex-flow: row nowrap;
}

.view-member-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 350px;
}

.channels .members-table .ant-table .ant-table-thead::before {
  width: 99.7%;
}

.member-modal {
  .ant-modal-content {
    background-color: @table-cell-color;
    border-radius: 24px;
    width: auto;
  }

  .channels .ant-table .ant-table-thead>tr>th {
    padding: 17px;
    border-bottom: none;
    color: @white-color;
  }

  .ant-table-body {
    height: 350px;
  }
}

.analytics-illustration-members {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 250px;
  padding: 33px;
  flex-direction: column;

  svg {
    max-width: 300px;
    height: auto;
  }

  .text-section {
    color: white;
  }
}