@import '../../../styles/variables.less';
@import '../../../styles/global/variables.less';

.ant-layout-sider-children {
  min-height: calc(100vh - 75px);
  overflow: auto;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: normal;

  .ant-menu-submenu-expand-icon,
  .ant-menu-submenu-arrow {
    right: 21px;
  }

  .gx-icon-btn {
    padding: 12px 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: @h2-font-size;
    color: @black-color;
  }

  @media (min-width: 1370px) {
    .heading .icon.pointer button {
      margin-left: 50px;
    }
  }

  .gx-sidebar-content {
    border-right: none;
    height: calc(100vh - 325px);
    overflow: auto;

    .ant-menu.ant-menu-dark {
      background: transparent;
    }

    .ant-menu-dark .ant-menu-submenu-title:hover {
      color: @white-color;
    }

    .ant-menu-dark .ant-menu-item:hover > span > a {
      color: @secondary-green-color;
    }

    .ant-menu-submenu-title:hover .ant-menu-submenu-arrow {
      &:after,
      &:before {
        background: @white-color;
      }
    }

    .ant-menu-submenu-title .ant-menu-item-icon {
      order: 1;
      margin-right: 20px;
    }

    .ant-menu-dark.ant-menu-submenu-open
      .ant-menu-submenu-selected
      .ant-menu-submenu-title {
      background: @secondary-background-color;
    }

    .ant-menu-dark .ant-menu-submenu-open {
      background: @secondary-background-color;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;

      .ant-menu-submenu-title,
      .ant-menu-submenu-title:hover {
        color: @secondary-red-color;
      }
    }

    .ant-menu-dark .ant-menu-item-selected > span > a {
      color: @secondary-green-color;
    }

    .ant-menu-dark .ant-menu-item:hover {
      color: @secondary-green-color;
    }

    .ant-divider-horizontal {
      margin: unset;
    }

    .ant-divider {
      background-color: @black-color;
    }

    .ant-layout-sider
      .ant-menu-submenu
      > .ant-menu.ant-menu-sub
      .ant-menu-item {
      padding-left: 20px;
    }

    .ant-menu-submenu
      .ant-menu-submenu-popup
      .ant-menu
      .ant-menu-dark
      .ant-menu-submenu-placement-rightTop {
      background: @secondary-green-color;
    }

    .menu-theme {
      background-color: @secondary-background-color;
      border-radius: 20px;
      max-height: 282px;
      transition: all 0.5s ease-out;

      ul,
      li {
        list-style: none;
      }
    }

    .no-menu-theme {
      background-color: transparent;
      max-height: 54px;
      transition: all 0.5s ease-out;

      .sb-project-avatar {
        background-color: @secondary-background-color;
      }
    }

    .ant-menu-inline.ant-menu-root
      .ant-menu-submenu-title
      > .ant-menu-title-content {
      display: block;
    }
  }

  .sb-open-menu {
    overflow: hidden;
    max-height: 230px;
    transition: all 0.5s ease-out;
    border-top: 1px solid @black-color;

    li:hover {
      svg path {
        fill: @background-color;
      }
    }

    .active svg {
      fill: @secondary-green-color;
    }

    .active svg path {
      fill: @secondary-green-color;
    }
  }

  .sb-close-menu {
    overflow: hidden;
    max-height: 0;
    transition: all 0.5s ease-out;
  }

  .sb-project-avatar {
    background-color: @background-color;

    &-text {
      color: @primary-color;
    }
  }

  .ant-divider {
    background-color: @black-color;
  }

  .login-icon {
    margin-bottom: 15px;
    width: 100%;

    .ant-menu.ant-menu-dark {
      background: transparent;
      margin-bottom: 16px;
    }

    .ant-menu-submenu-arrow {
      display: none;
    }

    .ant-avatar.ant-avatar-lg {
      background-color: @table-cell-color;
    }
  }
}

.ant-menu-submenu-popup .ant-menu-sub.ant-menu .ant-menu-item.logout-link {
  background: #1f282d;
}

.ant-menu-dark .ant-menu-item.logout-link:hover {
  color: @secondary-green-color;
}

.heading {
  padding-top: 0;
}

.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
.ant-menu-dark .ant-menu-item-selected {
  color: @secondary-green-color;
}

.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon path {
  fill: @secondary-green-color;
}

.ant-layout-sider-children .gx-sidebar-content .menu-theme {
  margin: 5px;
}

.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title {
  display: flex;
  justify-content: center;
}

.main-logo {
  color: @table-cell-color;
  transition: ease 0.4s;
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;

  &.close {
    width: 100%;
    overflow: hidden;
  }
}

.ant-layout-sider {
  .login-menu {
    .user-login-email {
      .ant-menu-title-content {
        display: block;
        text-overflow: ellipsis;
        overflow: auto;
      }

      .ant-menu-submenu-title {
        font-size: 16px;
        color: @black-color;
        padding: 0 14px !important;

        .login-name {
          color: @white-color;
          font-size: 14px;
          overflow: hidden;
          flex-shrink: 1;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: block;
          padding-right: 30px;
        }

        .ant-menu-submenu-expand-icon {
          height: 24px;
          width: 24px;
          fill: #fff;
        }
      }

      .ant-menu-submenu-title:hover {
        color: @secondary-green-color;
      }

      &.ant-menu-submenu-active,
      &.ant-menu-submenu-open {
        .ant-menu-submenu-title {
          color: @secondary-green-color;
        }
      }
    }
  }
}

.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  margin-right: 0px;
}

.ant-menu.ant-menu-sub {
  .sub-menu-divider {
    width: 100%;
    height: auto;
    padding: 0;
  }
}
