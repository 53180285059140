@import '../../styles/variables.less';
@import '../../styles/global/variables.less';

.dashboard.full-height-card.landing-card .ant-card-body {
  padding: 61px 96px 34px;
  height: 100vh;
}

.ant-input-affix-wrapper.search-input,
.ant-input-affix-wrapper.search-input .ant-input {
  background-color: transparent;
  outline: none;
}