@import '../../styles/variables.less';
@import '../../styles/global/variables.less';

.ant-table {
  .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .ant-table-column-sorter {
    margin-left: 4px;
  }

  td:nth-child(2) {
    font-weight: 500;
  }

  td.ant-table-column-sort {
    background-color: @white-color;
  }

  .ant-table-placeholder {
    .ant-table-cell {
      border: none;
    }
  }

  .ant-table-placeholder {
    height: calc(100vh - 418px);
  }

  .ant-table-thead>tr>th {

    text-align: center;
  }


  .ant-table-thead .ant-table-cell.ant-table-column-has-sorters:hover {
    color: @white-color;
  }

  .ant-table-thead .ant-table-cell:hover {
    color: @white-color;
  }

  .ant-table-thead .ant-table-cell.ant-table-column-has-sorters {
    height: 51px;
    padding: 0px;
  }

  .ant-table-tbody>tr>td {
    color: @white-color;
    font-size: 13px;
    height: 51px;
    padding: 0px;
    text-align: center;
    border-bottom: none;
    background-color: @table-cell-color;
  }

  .ant-table-tbody>tr.ant-table-row:hover>td {
    color: @white-color;
    background-color: @table-cell-color;
  }

  & .ant-table-tbody {
    & .anticon {
      font-size: @h6-font-size;
      padding: 0 4px;
    }

    & .ant-btn {
      margin: 0;
      padding: 1px 8px;
      height: auto;
    }
  }

  .ant-table-filter-column {
    justify-content: unset;
  }

  .ant-table-column-title {
    flex: unset;
  }

  .ant-table-column-sorters {
    justify-content: unset;
  }

  .ant-table-summary {
    .ant-table-cell {
      color: @black-color;
      font-weight: 500;
    }
  }
}

.ant-table-wrapper {
  .ant-table {
    background: transparent;
  }
}

.has-scroll-data {
  .ant-table {
    background: transparent;

    .ant-table-body {
      height: calc(100vh - @table-height-with-data);
    }
  }
}

.has-scroll-data-search {
  .ant-table {
    background: transparent;

    .ant-table-body {
      height: calc(100vh - @table-height-with-data-search);
    }
  }
}

.no-scroll-data {
  .ant-table {
    background: transparent;

    .ant-table-body {
      height: calc(100vh - @table-height-without-data);
    }
  }
}

.no-scroll-no-data {
  .ant-table {
    background: transparent;

    .ant-table-body {
      height: calc(100vh - @table-height-with-data);

      .ant-table-placeholder {
        height: calc(100vh - 520px);
      }
    }
  }
}

.ant-table-tbody>tr>td:first-child span {
  margin-left: 10px;
}

.ant-table-wrapper .ant-spin-blur {
  height: calc(100vh - 470px);
}