@import '../../styles/variables.less';
@import '../../styles/global/variables.less';

.full-height-card .ant-card-body {
  padding: 35px 20px 20px 20px;
  height: 100%;

  .ant-tabs {
    height: 100%;
  }
}

.analytic-title {
  margin: 30px 0 0 30px;
}

.global-container .ant-select-arrow {
  color: @table-cell-color;
}

.text-section {
  font-size: 24px;
  font-weight: 400;
}

.ant-tabs-nav-operations:hover {
  .down-arrow {
    display: none;
  }

  .up-arrow {
    display: block;
  }
}

.ant-tabs-nav-operations {
  .up-arrow {
    display: none;
  }

  .down-arrow {
    display: block;
  }
}

.setting.ant-tabs-tabpane {
  overflow: auto;

  .add-row {
    border: 1px solid #9AB999;
  }
}