@import '../../styles/variables.less';
@import '../../styles/global/variables.less';

.full-height-card.landing-card .ant-card-body {
  padding: 61px 96px 10px;
}

.ant-input-affix-wrapper.search-input, .ant-input-affix-wrapper.search-input .ant-input {
  background-color: transparent;
  outline: none;
}