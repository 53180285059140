.sb-otp-input {
  margin-right: 10px;
  input {
    width: 2.5em !important;
    text-align: center;
    border-radius: 10px;
    height: 2.5em;
  }
}
.sb-login-btn {
  background-color: @primary-red-color;
  color: @white-color;
  font-size: @h5-font-size;
}
.sb-send-again {
  color: @primary-color;
  padding: 0%;
  cursor: pointer;
  &:hover {
    color: @primary-color;
  }
  &:disabled {
    color: @header-text-color;
  }
}

.gx-login-header {
  .gx-login-header-title {
    font-size: 20px;
  }
  .gx-login-header-subtitle {
    font-size: 14px;
  }
}
