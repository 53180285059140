.analytic-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: @size-30;
  color: @black-color;
}

.global-container {
  margin-left: 40px;
  margin-top: 40px;
}

.analytic-dropdown {
  position: relative;
  height: 42px;
  border-radius: 50px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  color: @black-color;
  border: 1px solid @normal-color;
  border-radius: 10px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.val {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: @h5-font-size;
  color: @primary-red-color;
}

.global-chart {
  margin: 5%;
}

.copy-text {
  width: 64px;
  height: 41px;
  left: 1359px;
  top: 354px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: @font-size-24;
  line-height: 170.23%;
  color: @send-color;
}