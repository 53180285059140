@import '../../styles/variables.less';
@import '../../styles/global/variables.less';

.template h1 {
  color: @white-color;
}

.template .ant-table {
  background: transparent;
}

.template .ant-table .ant-table-thead > tr > th {
  background: transparent;
}

.template .ant-table .ant-table-thead {
  position: relative;
}

.template .ant-table .ant-table-thead::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  border: 1px solid @secondary-green-color;
  border-radius: 20px;
}

.template .ant-table .ant-table-thead > tr > th {
  padding: 0px;
  border-bottom: none;
  color: @white-color;
}

.template .ant-table .ant-table-thead > tr > th.ant-table-cell-scrollbar {
  display: none;
}

.template .ant-table .ant-table-tbody > tr > td {
  border-bottom: none;
  color: @white-color;
}

.template .ant-table .ant-empty-description {
  color: @white-color;
}

.template .ant-table .ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background-color: transparent;
}

.ant-table .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: @secondary-green-color;
  color: @table-cell-color;
}

.ant-table .ant-table-tbody > tr.ant-table-row:hover > td .anticon-edit svg {
  fill: @table-cell-color;
}

.ant-table
  .ant-table-tbody
  > tr.ant-table-row:hover
  > td
  .anticon-edit:hover
  svg {
  fill: #fff;
}

.template .edit-body span {
  margin: 5px;
}

.template .action-button button.ant-btn {
  margin: 2px;
}

.template .primary-button:hover {
  background-color: @primary-color;
  color: @white-color;
  border-color: @primary-color;
}

.template .ant-btn:active {
  color: @white-color;
  background-color: @primary-color;
}

.template .ant-btn:focus {
  color: @white-color;
  background-color: @primary-color;
}

.template .ant-table-tbody > tr.ant-table-row:hover > td:first-child {
  border-radius: 20px 0 0 20px;
}

.template .ant-table-tbody > tr.ant-table-row:hover > td:last-child {
  border-radius: 0 20px 20px 0;
}

.template .ant-table-tbody > tr > td {
  transition: none;
}

.template .action-button button.ant-btn:hover {
  color: @white-color;
}

.changes-modal-title {
  color: white;
  font-size: 16px;
  margin-bottom: 10px;
}

.body-modal-title {
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: @font-size-15;
  color: @white-color;
}

.preview {
  width: 100%;
  height: 70vh;
  background: @white-color;
}

.copy-template-modal {
  .ant-form-item .ant-select {
    width: 100%;
  }
}
