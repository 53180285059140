@import '../../styles/variables.less';
@import '../../styles/global/variables.less';

// @media screen and (min-width: 1024px) {
//   .full-height-card.add-projects .ant-card-body {
//     padding: 132px 101px 176px;
//     height: 100vh;
//   }
// }

.add-projects .input-box {
  max-width: 100%;
}

.add-projects .ant-select {
  width: 100%;
  background: transparent;
}

.add-projects
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background: @white-color;
}

.add-projects
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 10px;
}

.add-projects .ant-form-item .ant-select {
  width: 100%;
}

.add-projects .input-box {
  max-width: 100%;
}

.project-wrapper .view-project {
  background-color: transparent;
  border: 0;
}

.project-wrapper .delete-project {
  background-color: transparent;
  border: 0;
}

.project-wrapper .add-member {
  background-color: transparent;
  border: 0;
}

.project-wrapper .ant-btn:hover {
  background: transparent;
}

.full-height-card.landing-card .project-wrapper .ant-card-body {
  padding: 0;
}

.full-height-card.landing-card .ant-card {
  background: transparent;
  border: 0;
}

.add-projects .ant-row {
  display: block;
}

.add-projects .ant-row label {
  font-size: 15px;
}

.add-projects .ant-row .ant-form-item-control {
  margin-top: 10px;
}

.add-project .ant-row .ant-form-item-control {
  margin-top: 10px;
}

.add-project .add-container {
  height: auto;
}

.project-wrapper .ant-btn:active {
  background: transparent;
}

.anticon.anticon-loading.anticon-spin {
  color: @primary-red-color;
}

.environment-modal .ant-modal-content {
  background-color: @table-cell-color;
  border-radius: 24px;
  width: 500px;
}

.environment-modal .input-box {
  max-width: 100%;
}

.environment-modal .add-container {
  height: auto;
  padding: 5px;
}

.environment-modal {
  &.delete-env-modal {
    .add-container {
      p {
        color: @white-color;
        font-size: 18px;
        padding-right: 50px;
        span {
          color: @secondary-red-color;
          font-weight: 600;
        }
      }
    }
  }
}

.gx-main-content-wrapper .env-dropdown .ant-select-dropdown .create-new-env {
  background-color: #000000;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 5px;
  padding: 10px;
  display: block;
  font-weight: 600;
  text-align: center;
}

.gx-main-content-wrapper .env-dropdown .ant-select-dropdown {
  z-index: 999;
}

@media screen and (max-width: 1024px) {
  .ant-card.full-height-card.add-projects .ant-card-body {
    padding: 80px 20px;
  }

  .ant-card.full-height-card.landing-card .ant-card-body {
    padding: 40px 20px;
  }

  .ant-card.full-height-card
    .ant-card-body
    .ant-tabs-tab.ant-tabs-tab
    .ant-tabs-tab-btn {
    padding: 10px 20px;
  }

  .full-height-card .ant-card-body .global-container {
    margin-left: 20px;
    margin-top: 20px;
  }
}

.ant-select-arrow {
  color: @table-cell-color;
}

.edit-body-modal .ant-modal-content {
  background-color: @table-cell-color;
  border-radius: 24px;
  max-width: 1400px;
  padding: 10px;
}

.ant-modal {
  display: flex;
  justify-content: center;
}

.env-dropdown {
  .rc-virtual-list-scrollbar-thumb {
    display: none;
  }

  .rc-virtual-list-holder {
    overflow-y: auto !important;
  }

  .rc-virtual-list-holder-inner {
    margin: 5px;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  .ant-select-selection-item {
    .anticon-delete {
      display: none;
    }
  }

  .rc-virtual-list {
    .anticon-delete {
      font-size: 16px;
    }
  }
}
