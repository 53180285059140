@import '../../styles/variables.less';
@import '../../styles/global/variables.less';

.setting {
  font-size: 24px;
  padding: 0;

  .ant-row {
    p {
      margin: 0;
      padding: 14px;
      font-size: 14px;
    }
  }

  & > .ant-row {
    margin-top: 0;
    border: 1px solid @secondary-green-color;
    border-radius: 20px;
  }

  .input-box {
    width: 100%;
    max-width: 100%;
  }

  .ant-col.ant-col-21.ant-form-item-control {
    padding-left: 40px;
    min-width: 60%;
  }

  .ant-col-3 {
    min-width: 120px;
  }

  .ant-col.ant-col-20.ant-form-item-control {
    padding-left: 50px;
    min-width: 60%;
  }

  .ant-col-4 {
    min-width: 120px;
  }

  .primary-button {
    margin-right: 16px;
    margin-top: 20px;
  }

  .add-title {
    align-items: center;
  }
}

.setting .coming-soon {
  font-size: 64px;
  color: antiquewhite;
  font-family: monospace;
}

.setting .steps-content {
  display: block;
}

.setting {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    min-width: 250px;
    background-color: @primary-red-color;
    color: @white-color;
    border-radius: 10px;
    border: 0;
  }

  .ant-select-dropdown {
    background-color: @primary-red-color;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.2);

    .ant-empty-description {
      color: @white-color;
    }
  }

  .ant-select-selection-placeholder {
    color: @white-color;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled),
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: @black-color;
  }

  .ant-select-item {
    font-size: @h6-font-size;
    color: @select-color;
    border-radius: 10px;
    margin-bottom: 5px;
    padding: 6px;
  }

  .ant-select-arrow {
    color: @white-color;
  }

  .ant-select-item-option-content {
    color: @white-color;
  }

  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: @white-color;
  }
}

.platform h1 {
  color: @white-color;
}

.platform .ant-table {
  background: transparent;
}

.platform .ant-table .ant-table-thead > tr > th {
  background: transparent;
}

.platform .ant-table .ant-table-thead {
  position: relative;
}

.platform .ant-table .ant-table-thead::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  border: 1px solid @secondary-green-color;
  border-radius: 20px;
}

.platform .ant-table .ant-table-thead > tr > th {
  border-bottom: none;
  color: @white-color;
}

.platform .ant-table .ant-table-thead > tr > th.ant-table-cell-scrollbar {
  display: none;
}

.platform .ant-table .ant-table-tbody > tr > td {
  border-bottom: none;
  color: @white-color;
}

.platform .ant-table .ant-empty-description {
  color: @white-color;
}

.platform .ant-table .ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background-color: transparent;
}

.platform .ant-table .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: @secondary-green-color;
}

.platform .edit-body span {
  margin: 5px;
}

.platform .action-button button.ant-btn {
  margin: 3px;
}

.platform .primary-button:hover {
  background-color: @primary-color;
  color: @white-color;
  border-color: @primary-color;
}

.platform .ant-btn:active {
  color: @white-color;
  background-color: @primary-color;
}

.platform .ant-btn:focus {
  color: @white-color;
  background-color: @primary-color;
}

.platform .ant-table-tbody > tr.ant-table-row:hover > td:first-child {
  border-radius: 20px 0 0 20px;
}

.platform .ant-table-tbody > tr.ant-table-row:hover > td:last-child {
  border-radius: 0 20px 20px 0;
}

.platform .ant-table-tbody > tr > td {
  transition: none;
}

.platform .action-button button.ant-btn:hover {
  color: @white-color;
}

.setting.add-container {
  padding: 40px;
}

.add-container .customize-password {
  border-radius: 10px;

  .ant-input {
    max-width: 100%;
    border: none !important;
  }
}
