@import '../../styles/variables.less';
@import '../../styles/global/variables.less';

.sender h1 {
  color: @white-color;
}

.sender .ant-table {
  background: transparent;
}

.sender .ant-table .ant-table-thead>tr>th {
  background: transparent;
}

.sender .ant-table .ant-table-thead {
  position: relative;
}

.sender .ant-table .ant-table-thead::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  border: 1px solid @secondary-green-color;
  border-radius: 20px;
}

.sender .ant-table .ant-table-thead>tr>th {
  padding: 0px;
  border-bottom: none;
  color: @white-color;
}

.sender .ant-table .ant-table-thead>tr>th.ant-table-cell-scrollbar {
  display: none;
}

.sender .ant-table .ant-table-tbody>tr>td {
  border-bottom: none;
  color: @white-color;
}

.sender .ant-table .ant-empty-description {
  color: @white-color;
}

.sender .ant-table .ant-table-tbody>tr.ant-table-placeholder:hover>td {
  background-color: transparent;
}

.sender .ant-table .ant-table-tbody>tr.ant-table-row:hover>td {
  background-color: @secondary-green-color;
}

.sender .verify-status span {
  margin: 5px;
}

.sender .ant-table .ant-table-tbody .ant-btn.verification {
  margin-right: 10px;
}

.sender .ant-table .ant-table-tbody .ant-btn.verified-success {
  background-color: transparent;
  color: @dark-green;
  border-color: @dark-green;
}

.sender .ant-btn:active {
  color: @white-color;
  background-color: @primary-color;
}

.sender .ant-btn:focus {
  color: @white-color;
  background-color: @primary-color;
}

.sender .ant-table-tbody>tr.ant-table-row:hover>td:first-child {
  border-radius: 20px 0 0 20px;
}

.sender .ant-table-tbody>tr.ant-table-row:hover>td:last-child {
  border-radius: 0 20px 20px 0;
}

.sender .ant-table-tbody>tr>td {
  transition: none;
}

.sender .action-button button.ant-btn:hover {
  color: @white-color;
}

.toast {
  text-align: center;
  font-size: 12px;
}

.toast-heading {
  color: @white-color;
}

.gx-main-content-wrapper .platform-dropdown .ant-select-dropdown .create-new-platform {
  background-color: #000000;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 5px;
  padding: 10px;
  display: block;
  font-weight: 600;
  text-align: center;
  color: @white-color;
  font-size: 12px;
  cursor: pointer;
}