@import '../../styles/variables.less';
@import '../../styles/global/variables.less';

.auth-keys {
  .auth-key-copy {
    svg {
      cursor: pointer;
    }
  }
}

.auth-details {
  font-size: 24px;
  padding: 0;

  .ant-row {
    margin: 0 !important;

    p {
      margin: 0;
      padding: 14px;
      font-size: 14px;
    }
  }

  & > .ant-row {
    margin-top: 0;
    border: 1px solid @secondary-green-color;
    border-radius: 20px;
  }

  .input-box {
    width: 100%;
    max-width: 100%;
  }

  .ant-col.ant-col-21.ant-form-item-control {
    padding-left: 50px;
    min-width: 60%;
  }

  .ant-col-3 {
    min-width: 120px;
  }

  .primary-button {
    margin-right: 16px;
    margin-top: 20px;
  }

  .add-title {
    align-items: center;
  }
}
