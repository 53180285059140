@import '../../styles/variables.less';
@import '../../styles/global/variables.less';

.logs h1 {
  color: @white-color;
}

.logs .ant-table {
  background: transparent;
}

.logs .ant-table .ant-table-thead > tr > th {
  background: transparent;
}

.logs .ant-table .ant-table-thead {
  position: relative;
}

.logs .ant-table .ant-table-thead::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  border: 1px solid @secondary-green-color;
  border-radius: 20px;
}

.logs .ant-table .ant-table-thead > tr > th {
  border-bottom: none;
  color: @white-color;
}

.logs .ant-table .ant-table-thead > tr > th.ant-table-cell-scrollbar {
  display: none;
}

.logs .ant-table .ant-table-tbody > tr > td {
  border-bottom: none;
  color: @white-color;
}

.logs .ant-table .ant-empty-description {
  color: @white-color;
}

.logs .ant-table .ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background-color: transparent;
}

.logs .ant-table .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: @secondary-green-color;
}

.send-raw-email .ant-row {
  flex-flow: row nowrap;
}

.logs {
  .ant-table-wrapper .ant-table {
    .ant-table-header th,
    .ant-table-body td {
      padding: 0 10px;
    }
  }

  .re-send {
    .ant-btn {
      border-radius: 8px;
    }
    span {
      margin: 5px;
    }
    border-radius: 8px;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    &:first-child {
      border-radius: 20px 0 0 20px;
    }

    &:last-child {
      border-radius: 0 20px 20px 0;
    }
  }

  .ant-table-tbody > tr > td {
    transition: none;
  }
}
