@import '../../styles/variables.less';
@import '../../styles/global/variables.less';

.full-height-card .ant-card-body {
  padding: 35px 20px 20px 20px;
  height: 100%;

  .ant-tabs {
    height: 100%;
  }
}

.analytic-title {
  margin: 30px 0 0 30px;
}

.analtics-cards-section {
  display: flex;
  margin-bottom: 30px;

  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
  }
}

.analytics {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.analytics-top {
  display: flex;
  justify-content: space-between;
}

.analytics-card {
  width: 150px;
  height: 125px;
  border: 1px solid gray;
  border-radius: 15px;
  margin-right: 30px;
  margin-top: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

  .analytics-card-title {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    padding: 12px 0px 10px;
    border-bottom: 0.5px solid gray;

    @media screen and (max-width: 1024px) {
      padding: 10px 0px 5px;
      font-size: 12px;
    }

    @media screen and (max-width: 910px) {
      padding: 5px 0px 5px;
      font-size: 8px;
    }
  }

  .analytics-card-count {
    font-size: 45px;
    text-align: center;
    font-weight: normal;

    @media screen and (max-width: 1024px) {
      font-size: 35px;
    }

    @media screen and (max-width: 910px) {
      font-size: 25px;
    }
  }

  .analytics-card-per {
    font-size: 35px;
    text-align: center;
    font-weight: normal;
    margin-top: 5px;

    @media screen and (max-width: 1024px) {
      font-size: 25px;
    }

    @media screen and (max-width: 910px) {
      font-size: 15px;
    }
  }

  .analytics-card-small-count {
    font-size: 13px;
    text-align: center;
    font-weight: normal;

    @media screen and (max-width: 1024px) {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 1024px) {
    width: 110px;
    height: 100px;
  }

  @media screen and (max-width: 910px) {
    width: 90px;
    height: 60px;
  }

  @media screen and (max-width: 800px) {
    width: 80px;
    margin-right: 10px;
  }
}
.global-container.analytics {
  margin: 0;
}
.analytics-line-chart {
  width: "inherit";
  padding: 30px 0px 40px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  border: 1px solid gray;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.analytics-range-dropdown {
  margin-right: 20px;
  margin-bottom: 10px;
}

.line-chart {
  width: 1000px;

  @media screen and (max-width: 1280px) {
    width: 700px;
  }

  @media screen and (max-width: 921px) {
    width: 500px;
    overflow: auto;
  }
}

.analytics-dropdown {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.analytics-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.analytics-illustration {
  .ant-empty-description {
    color: @white-color;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 250px;
  height: calc(100vh - 350px);
  padding: 33px;
  flex-direction: column;

  svg {
    max-width: 300px;
    height: auto;
  }
}